import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LandingComponent } from './pages/landing/landing.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { FooterComponent } from './components/footer/footer.component'
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { environment } from 'src/environments/environment'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { ContactComponent } from './components/contact/contact.component'
import { ReactiveComponentModule } from '@ngrx/component'

import * as fromStore from './store/index'
import { AboutComponent } from './components/about/about.component'
import { GarageComponent } from './pages/garage/garage.component'
import { PaintComponent } from './pages/paint/paint.component'
import { CleaningComponent } from './pages/cleaning/cleaning.component'
import { WebLayoutComponent } from './components/weblayout/weblayout.component'

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    GarageComponent,
    PaintComponent,
    CleaningComponent,
    WebLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule,
    StoreModule.forRoot({ ...fromStore.reducers }),
    EffectsModule.forRoot(fromStore.effects),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
