import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'web-main-layout',
  templateUrl: './weblayout.component.html',
  styleUrls: ['./weblayout.component.css'],
})
export class WebLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
