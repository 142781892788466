import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { IContactInfo } from 'src/app/models/contact.model'
import { OnSubmitInfo } from 'src/app/store/contact/contact.actions'
import { getSubmitted } from 'src/app/store/contact/contact.selectors'

@Component({
  selector: 'contact-us-form',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  formContactUs = new FormGroup({})
  phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  contactFormSubmitted$ = this.store.select(getSubmitted)
  submittedIncomplete = false

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.buildForm()
  }

  buildForm() {
    this.formContactUs = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('', [Validators.pattern(this.phoneRegex), Validators.minLength(10), Validators.required]),
      city: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(15)]),
      message: new FormControl('', [Validators.maxLength(320), Validators.required]),
    })
  }

  fieldError(field: string) {
    if (this.formContactUs && this.submittedIncomplete) {
      if (this.formContactUs.get(field)?.errors) {
        return true
      }
    }
    return false
  }

  onSubmitForm() {
    if (this.formContactUs.valid) {
      this.submittedIncomplete = false
      const contactInfo: IContactInfo = {
        firstName: this.formContactUs.value.firstName,
        lastName: this.formContactUs.value.lastName,
        email: this.formContactUs.value.email,
        phone: this.formContactUs.value.phone,
        city: this.formContactUs.value.city,
        message: this.formContactUs.value.message,
      }
      this.store.dispatch(new OnSubmitInfo(contactInfo))
    } else {
      this.submittedIncomplete = true
    }
  }
}
