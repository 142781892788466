import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ContactActions, ContactActionTypes } from './contact.actions'

export interface IContactState {
  submitted: boolean
}
export interface State extends EntityState<IContactState> {
  submitted: boolean
}

export const adapter: EntityAdapter<IContactState> = createEntityAdapter<IContactState>()

export const initialState: State = adapter.getInitialState({
  submitted: false,
})

export function reducer(state = initialState, action: ContactActions): State {
  switch (action.type) {
    case ContactActionTypes.onSubmitInfo:
      return {
        ...state,
        submitted: true,
      }
    default:
      return state
  }
}

export const userEntitySelectors = adapter.getSelectors()
