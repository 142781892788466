import { Action } from '@ngrx/store'
import { IContactInfo } from 'src/app/models/contact.model'

export enum ContactActionTypes {
  onInitContact = '[Contact] on init',
  onSubmitInfo = '[Contact] on submit info',
}

export class OnSubmitInfo implements Action {
  readonly type = ContactActionTypes.onSubmitInfo
  constructor(public payload: IContactInfo) {}
}

export type ContactActions = OnSubmitInfo
