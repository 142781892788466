import { NgModule } from '@angular/core'
import { Routes, RouterModule, ExtraOptions } from '@angular/router'
import { WebLayoutComponent } from './components/weblayout/weblayout.component'
import { CleaningComponent } from './pages/cleaning/cleaning.component'
import { GarageComponent } from './pages/garage/garage.component'

import { LandingComponent } from './pages/landing/landing.component'
import { PaintComponent } from './pages/paint/paint.component'

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  {
    path: 'garage',
    component: WebLayoutComponent,
    children: [
      {
        path: '',
        component: GarageComponent,
      },
    ],
  },
  {
    path: 'paint',
    component: WebLayoutComponent,
    children: [
      {
        path: '',
        component: PaintComponent,
      },
    ],
  },
  {
    path: 'clean',
    component: WebLayoutComponent,
    children: [
      {
        path: '',
        component: CleaningComponent,
      },
    ],
  },
]

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
}
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
