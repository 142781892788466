import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { switchMap } from 'rxjs/operators'
import { WebContactRepository } from 'src/app/repositories/contact.repository'
import { ContactActionTypes, OnSubmitInfo } from './contact.actions'

@Injectable()
export class ContactEffects {
  constructor(private actions$: Actions, private store: Store, private webContactRepository: WebContactRepository) {}

  onSubmitInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnSubmitInfo>(ContactActionTypes.onSubmitInfo),
        switchMap(action => this.webContactRepository.add(action.payload))
      ),
    { dispatch: false }
  )
}
